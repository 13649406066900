const handleApiError = (error, vm, msg) => {
  console.error(error);

  var spinner = document.getElementById("spinner");
  if (spinner) {
    spinner.parentNode.removeChild(spinner);
  }

  let toastMsg =
    "There was an error processing your request. Please try again later.";
  if (msg) {
    toastMsg = msg;
  }
  vm.$bvToast.toast(toastMsg, {
    title: "Error",
    autoHideDelay: 5000,
    variant: "danger",
  });
};

export default handleApiError;
